import React, { useState, useEffect } from "react";
import { db } from "../firebase/config";

import {
  arrayUnion,
  setDoc,
  doc,
  updateDoc,
  collection,
  getDocs,
} from "firebase/firestore";

const Invite = () => {
  const [guestEmailAddress, setGuestEmailAddress] = useState();
  const [noOfGuests, setNoOfGuests] = useState(0);
  const [guestFirstName, setGuestFirstName] = useState();
  const [guestSecondName, setGuestSecondName] = useState();
  const [secondGuestFirstName, setSecondGuestFirstName] = useState();
  const [secondGuestSecondName, setSecondGuestSecondName] = useState();
  const [thirdGuestFirstName, setThirdGuestFirstName] = useState();
  const [thirdGuestSecondName, setThirdGuestSecondName] = useState();
  const [guestType, setGuestType] = useState(true);
  const concatGuestName1 = guestFirstName + guestSecondName;
  const concatGuestName2 = secondGuestFirstName + secondGuestSecondName;
  const concatGuestName3 = thirdGuestFirstName + thirdGuestSecondName;
  const guestName1 = `${guestFirstName} ${guestSecondName}`;
  const guestName2 = `${secondGuestFirstName} ${secondGuestSecondName}`;
  const guestName3 = `${thirdGuestFirstName} ${thirdGuestSecondName}`;
  const fullGuest = guestType ? "Full" : "Evening";
  const dashboard = doc(db, "responses", `${fullGuest}`);

  const guestDetails = {
    [`${concatGuestName1}`]: {
      firstName: guestFirstName,
      secondName: guestSecondName,
      fullGuest: guestType,
      index: 0,
    },
  };

  const doubleGuestDetails = {
    [`${concatGuestName1}`]: {
      firstName: guestFirstName,
      secondName: guestSecondName,
      fullGuest: guestType,
      index: 0,
    },
    [`${concatGuestName2}`]: {
      firstName: secondGuestFirstName,
      secondName: secondGuestSecondName,
      fullGuest: guestType,
      index: 1,
    },
  };

  const tripleGuestDetails = {
    [`${concatGuestName1}`]: {
      firstName: guestFirstName,
      secondName: guestSecondName,
      fullGuest: guestType,
      index: 0,
    },
    [`${concatGuestName2}`]: {
      firstName: secondGuestFirstName,
      secondName: secondGuestSecondName,
      fullGuest: guestType,
      index: 1,
    },
    [`${concatGuestName3}`]: {
      firstName: thirdGuestFirstName,
      secondName: thirdGuestSecondName,
      fullGuest: guestType,
      index: 2,
    },
  };

  // adding docs
  const handleNewGuest = async (e) => {
    e.preventDefault();
    if (noOfGuests === 1) {
      await setDoc(doc(db, "rsvp", `${guestEmailAddress}`), guestDetails);
      updateDoc(dashboard, {
        awaitingResponse: arrayUnion(`${guestName1}`),
      }).catch((error) => {
        console.log(error);
      });
    } else if (noOfGuests === 2) {
      await setDoc(doc(db, "rsvp", `${guestEmailAddress}`), doubleGuestDetails);
      updateDoc(dashboard, {
        awaitingResponse: arrayUnion(`${guestName1}`, `${guestName2}`),
      }).catch((error) => {
        console.log(error);
      });
    } else if (noOfGuests === 3) {
      await setDoc(doc(db, "rsvp", `${guestEmailAddress}`), tripleGuestDetails);
      updateDoc(dashboard, {
        awaitingResponse: arrayUnion(
          `${guestName1}`,
          `${guestName2}`,
          `${guestName3}`
        ),
      }).catch((error) => {
        console.log(error);
      });
    }

    setGuestEmailAddress("");
    setGuestFirstName("");
    setGuestSecondName("");
    setSecondGuestFirstName("");
    setSecondGuestSecondName("");
    setThirdGuestFirstName("");
    setThirdGuestSecondName("");
    setGuestType("Full");
  };


  return (
    <form onSubmit={handleNewGuest}>
      <div className="flex flex-col items-center justify-items-center my-3 md:min-h-max m-auto">
        <h3 className="flex items-center justify-center text-center p-1 text-lg md:text-xl font-bold">
          Invite a New Guest
        </h3>
        <h3 className="flex items-center justify-center text-center p-1 text-lg md:text-xl font-bold">
          Email Address
        </h3>
        <input
          className="bg-gray-200 rounded pl-4 py-1 mb-2 focus:outline-none border mx-3"
          required
          type="text"
          placeholder="Guest's Email Address"
          value={guestEmailAddress}
          onChange={(e) => setGuestEmailAddress(e.target.value)}
        />
        <h3 className="flex items-center justify-center text-center p-1 text-lg md:text-xl font-bold">
          No of Guests
        </h3>
        <section>
          <label
            htmlFor={`radio1`}
            className="pl-[1.5rem] flex items-center p-1 text-md md:text-lg"
          >
            <input
              className="relative float-left -ml-[1.5rem] mr-1 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-tahiti"
              required
              id="radio1"
              type="radio"
              name="number"
              onChange={() => setNoOfGuests(1)}
            />
            <p>1</p>
          </label>
          <label
            htmlFor={`radio2`}
            className="pl-[1.5rem] flex items-center p-1 text-md md:text-lg"
          >
            <input
              className="relative float-left -ml-[1.5rem] mr-1 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-tahiti"
              type="radio"
              id="radio-2"
              name="number"
              onChange={() => setNoOfGuests(2)}
            />
            <p>2</p>
          </label>
          <label
            htmlFor={`radio2`}
            className="pl-[1.5rem] flex items-center p-1 text-md md:text-lg"
          >
            <input
              className="relative float-left -ml-[1.5rem] mr-1 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-tahiti"
              type="radio"
              id="radio3"
              name="number"
              onChange={() => setNoOfGuests(3)}
            />
            <p>3</p>
          </label>
        </section>
        <h3 className="flex items-center justify-center text-center p-1 text-lg md:text-xl font-bold">
          First name
        </h3>
        <input
          className="bg-gray-200 rounded pl-4 py-1 mb-2 focus:outline-none border mx-3"
          required
          type="text"
          placeholder="Guest's First Name"
          value={guestFirstName}
          onChange={(e) => setGuestFirstName(e.target.value)}
        />
        <h3 className="flex items-center justify-center text-center p-1 text-lg md:text-xl font-bold">
          Second Name
        </h3>
        <input
          className="bg-gray-200 rounded pl-4 py-1 mb-2 focus:outline-none border mx-3"
          required
          type="text"
          placeholder="Guest's Second Name"
          value={guestSecondName}
          onChange={(e) => setGuestSecondName(e.target.value)}
        />
        {noOfGuests >= 2 && noOfGuests < 4 && (
          <>
            <h3 className="flex items-center justify-center text-center p-1 text-lg md:text-xl font-bold">
              Second Guest First Name
            </h3>
            <input
              className="bg-gray-200 rounded pl-4 py-1 mb-2 focus:outline-none border mx-3"
              required
              type="text"
              placeholder="Guest's First Name"
              value={secondGuestFirstName}
              onChange={(e) => setSecondGuestFirstName(e.target.value)}
            />
            <h3 className="flex items-center justify-center text-center p-1 text-lg md:text-xl font-bold">
              Second Guest Second Name
            </h3>
            <input
              className="bg-gray-200 rounded pl-4 py-1 mb-2 focus:outline-none border mx-3"
              required
              type="text"
              placeholder="Guest's Second Name"
              value={secondGuestSecondName}
              onChange={(e) => setSecondGuestSecondName(e.target.value)}
            />
          </>
        )}
        {noOfGuests === 3 && (
          <>
            <h3 className="flex items-center justify-center text-center p-1 text-lg md:text-xl font-bold">
              Third Guest First Name
            </h3>
            <input
              className="bg-gray-200 rounded pl-4 py-1 mb-2 focus:outline-none border mx-3"
              required
              type="text"
              placeholder="Guest's First Name"
              value={thirdGuestFirstName}
              onChange={(e) => setThirdGuestFirstName(e.target.value)}
            />
            <h3 className="flex items-center justify-center text-center p-1 text-lg md:text-xl font-bold">
              Third Guest Second Name
            </h3>
            <input
              className="bg-gray-200 rounded pl-4 py-1 mb-2 focus:outline-none border mx-3"
              required
              type="text"
              placeholder="Guest's Second Name"
              value={thirdGuestSecondName}
              onChange={(e) => setThirdGuestSecondName(e.target.value)}
            />
          </>
        )}
        <h3 className="flex items-center justify-center text-center p-1 text-lg md:text-xl font-bold">
          Full day guest?
        </h3>
        <section>
          <label
            htmlFor={`radio4`}
            className="pl-[1.5rem] flex items-center p-1 text-md md:text-lg"
          >
            <input
              defaultChecked
              required
              type="radio"
              id="radio4"
              name="guestType"
              value={guestType}
              onChange={() => setGuestType(true)}
              className="relative float-left -ml-[1.5rem] mr-1 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-tahiti"
            />
            <p style={guestType ? { color: "#9966cb" } : { color: "black" }}>
              Full
            </p>
          </label>
          <label
            htmlFor={`radio5`}
            className="pl-[1.5rem] flex items-center p-1 text-md md:text-lg"
          >
            <input
              id="radio5"
              type="radio"
              name="guestType"
              value={guestType}
              onChange={() => setGuestType(false)}
              className="relative float-left -ml-[1.5rem] mr-1 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-tahiti"
            />
            <p style={!guestType ? { color: "#9966cb" } : { color: "black" }}>
              Evening
            </p>
          </label>
        </section>
        <button
          className="bg-tahiti hover:bg-green border border-black-500 hover:border-transparent py-1 mt-3 px-3 text-white rounded m-auto block"
          style={{ fontVariant: "small-caps" }}
          type="submit"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default Invite;
