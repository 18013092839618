import React, { useEffect, useState, useRef } from "react";
import Rsvp from "../components/Rsvp";
import { db, auth } from "../firebase/config";
import { useAuthContext } from "../hooks/useAuthContext";
import { doc, getDoc, updateDoc, collection } from "firebase/firestore";
import { signOut } from "firebase/auth";
import emailjs from "emailjs-com";

const Full = () => {
  const { user } = useAuthContext();
  const [isPending, setIsPending] = useState(false);
  const [guestNames, setGuestNames] = useState();
  const [guests, setGuests] = useState();
  const [artist, setArtist] = useState();
  const [track, setTrack] = useState();
  const email = user.email;
  const docRef = doc(db, "rsvp", email);
  const songDocRef = doc(db, "songs", "playlist");
  const RsvpRef = useRef(null);
  const [fullGuestStatus, setFullGuestStatus] = useState();
  const [occurrence, setOccurrence] = useState();

  const google =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2317.8918600190464!2d-1.5586560228699013!3d54.48248978911115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e99112356070b%3A0xf16d2690d31bda0!2sThe%20Croft%20Hotel!5e0!3m2!1sen!2suk!4v1724594825123!5m2!1sen!2suk";

  const errorMessage = {
    from_name: "Dan and Emma Wedding App",
    to_name: "The Happy Couple",
    message: `An error occurred in the app: the error was ${occurrence}, the user who experienced the error was: ${email}`,
    reply_to: "Bride and Groom",
  };

  const scrollToRsvp = () => {
    RsvpRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const greeting = (names) => {
    if (names.length === 1) {
      return `${names[0]}`;
    } else if (names.length === 2) {
      return `${names[0]} and ${names[1]}`;
    } else if (names.length === 3) {
      return `${names[0]}, ${names[1]} and ${names[2]}`;
    } else if (names.length === 4) {
      return `${names[0]}, ${names[1]}, ${names[2]} and ${names[3]}`;
    } else {
      return `${names[0]}, ${names[1]}, ${names[2]}, ${names[3]} and ${names[4]}`;
    }
  };

  const sortGuestsByIndex = (guestsToSort) => {
    const sortedGuests = guestsToSort.sort((a, b) => a.index - b.index);
    return sortedGuests;
  };

  useEffect(() => {
    const names = [];
    getDoc(docRef).then((snapshot) => {
      const result = snapshot.data();
      const unorderedGuests = Object.values(result);
      const sortedGuests = sortGuestsByIndex(unorderedGuests);
      setGuests(sortedGuests);
      setFullGuestStatus(sortedGuests[0].fullGuest);
      setIsPending(true);
      for (let i = 0; i < sortedGuests?.length; i++) {
        names.push(sortedGuests[i].firstName);
      }
      const welcome = greeting(names);
      setGuestNames(welcome);
    });
  }, []);

  const logoutEvent = (e) => {
    e.preventDefault();
    signOut(auth).catch((err) => {
      setOccurrence("failure when trying to logout");
      sendEmail(errorMessage);
    });
  };

  const sendConfirmation = () => {
    return `${guestNames} have responded.
    They have requested the following songs : ${track} - ${artist}`;
  };

  const updateSongSelection = (e) => {
    e.preventDefault();
    const emailMessage = {
      from_name: "Dan and Emma Wedding App",
      to_name: "The Happy Couple",
      message: sendConfirmation(),
      reply_to: "Bride and Groom",
    };
    sendEmail(emailMessage);
    updateDoc(songDocRef, {
      [`${artist}`]: track,
    }).catch((error) => {
      setOccurrence("failure when requesting a song");
      sendEmail(errorMessage);
    });
    setArtist("");
    setTrack("");
  };

  const sendEmail = (message) => {
    emailjs
      .send("service_ks5aro6", "template_3683fbb", message, "3_nlZ1YmfRa-FyB8d")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      {isPending && user && (
        <>
          <div className="max-w-none w-screen m-0 p-0 h-24 md:h-48 bg-[url('./assets/top.svg')] bg-cover bg-no-repeat"></div>
          <div className="flex flex-col items-center justify-items-center my-3 min-h-[45rem] sm:min-h-[54rem] max-w-[40rem] sm:max-w-[48rem] md:min-h-max bg-no-repeat m-auto">
            {/* <button
              className="pt-4 text-white uppercase rounded m-auto"
              style={{ fontVariant: "small-caps" }}
              onClick={logoutEvent}
            >
              sign out
            </button> */}
            <div className="text-center m-auto mx-3 w-auto content-center">
              <h1 className="text-4xl p-2">DAN & EMMA</h1>
              <h2 className="text-2xl p-0">ARE GETTING MARRIED</h2>
              <hr className="w-48 h-1 mx-auto my-1 bg-gray-100 border-3 my-3 rounded md:my-4 dark:bg-gray-700" />{" "}
              <>
                {fullGuestStatus && (
                  <>
                    <h2
                      className="flex items-center justify-center text-center p-3 text-lg md:text-xl font-bold pt-7"
                      style={{ fontVariant: "normal" }}
                    >
                      You're Invited!
                    </h2>
                    {guestNames?.length >= 1 && (
                      <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                        {guestNames}, we are very excited to invite you to our
                        wedding on 1st December 2024.
                      </p>
                    )}
                    <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                      We would love for you to join us for the wedding ceremony
                      at the Croft Hotel, in Darlington to hear us say, 'I do'.
                      And we hope that you can stay for good company, food,
                      drinks and music to follow at the wedding celebration on
                      the evening.
                    </p>
                    <p
                      className="text-center p-3 text-base md:text-lg"
                      onClick={scrollToRsvp}
                    >
                      Please
                      <span className="px-1 text-green font-bold hover:underline">
                        RSVP
                      </span>
                      by the 21st of September to tell us make it.
                    </p>
                    <div className="divide-y divide-dashed">
                      <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                        With love from Dan and Emma x
                      </p>
                    </div>
                  </>
                )}

                {!fullGuestStatus && (
                  <>
                    <h2
                      className="flex items-center justify-center text-center p-3 text-lg md:text-xl font-bold pt-7"
                      style={{ fontVariant: "normal" }}
                    >
                      You're Invited!
                    </h2>
                    {guestNames?.length >= 1 && (
                      <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                        {guestNames}, we are very excited to invite you to our
                        wedding Evening Reception on 1st December 2024.
                      </p>
                    )}
                    <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                      We are getting Married at the Croft Hotel, in Darlington
                      and would love for you to join us for the after party
                      starting at 7pm.
                    </p>
                    <p
                      className="text-center p-3 text-base md:text-lg"
                      onClick={scrollToRsvp}
                    >
                      Please
                      <span className="px-1 text-green font-bold hover:underline">
                        RSVP
                      </span>
                      by the 21st of September to tell us make it.
                    </p>
                    <div className="divide-y divide-dashed">
                      <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                        With love from Dan and Emma x
                      </p>
                    </div>
                  </>
                )}
                <hr className="w-48 h-1 mx-auto my-1 bg-gray-100 border-3 my-3 rounded md:my-4 dark:bg-gray-700" />
                {fullGuestStatus && (
                  <>
                    <h2
                      className="flex items-center justify-center text-center p-3 text-lg md:text-xl font-bold pt-7"
                      style={{ fontVariant: "normal" }}
                    >
                      {" "}
                      Our Wedding Ceremony
                    </h2>
                    <p className="text-center p-3 text-base md:text-lg">
                      Our Wedding Ceremony will be held at the Croft Hotel in
                      Darlington.
                    </p>
                    <p className="text-center p-3 text-base md:text-lg">
                      We recommend that all guests arrive by <b>12:45pm</b> to
                      give everyone the chance to mingle and get settled prior
                      to the ceremony starting.
                    </p>
                    <p className="text-center p-3 text-base md:text-lg">
                      The ceremony will take place in the on-site chapel at
                      <b> 1:30pm</b>.
                    </p>
                    <hr className="w-48 h-1 mx-auto my-1 bg-gray-100 border-3 my-3 rounded md:my-4 dark:bg-gray-700" />
                    <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                      The Croft Hotel
                    </p>
                    <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                      Northallerton Road
                    </p>
                    <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                      Darlington
                    </p>
                    <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                      DL2 2ST
                    </p>
                  </>
                )}
                {!fullGuestStatus && (
                  <>
                    <h2 className="flex items-center justify-center text-center p-3 text-lg md:text-xl font-bold pt-7">
                      Our Evening Reception
                    </h2>
                    <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                      Our evening reception will be held at the Croft Hotel, in
                      Darlington.
                    </p>
                    <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                      The venue will welcome evening guests from 7pm.
                    </p>
                    <hr className="w-48 h-1 mx-auto my-1 bg-gray-100 border-3 my-3 rounded md:my-4 dark:bg-gray-700" />{" "}
                    <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                      The Croft Hotel
                    </p>
                    <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                      Northallerton Road
                    </p>
                    <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                      Darlington
                    </p>
                    <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                      DL2 2ST
                    </p>
                  </>
                )}
                <hr className="w-48 h-1 mx-auto my-1 bg-gray-100 border-3 my-3 rounded md:my-4 dark:bg-gray-700" />
                <div className="my-6">
                  <iframe
                    title="croft-map"
                    width="100%"
                    height="300"
                    id="gmap_canvas"
                    src={google}
                  ></iframe>
                </div>
                <>
                  <hr className="w-48 h-1 mx-auto my-1 bg-gray-100 border-3 my-3 rounded md:my-4 dark:bg-gray-700" />
                  <h2 className="flex items-center justify-center text-center p-3 text-lg md:text-xl font-bold pt-7">
                    Accomodation
                  </h2>
                  <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                    There are a number of hotel rooms available at the venue,
                    but please note that these are not reserved exclusively for
                    our guests, so you may wish to book sooner rather than later
                    to avoid disappointment.
                  </p>
                  <p className="text-center p-3 pb-0 text-base md:text-lg">
                    You can ask for a 10% discount on any rooms you book for the
                    night prior to, or night of our wedding. To book, call the
                    venue directly on 01325 720319 and mention that you are a
                    guest at our wedding, or otherwise use the code{" "}
                    <i>croftwedding</i> when booking online.
                  </p>
                  <p className="justify-center text-center p-3 text-base md:text-lg">
                    You can also visit{" "}
                    <a
                      href="https://www.booking.com/searchresults.en-gb.html?ss=Croft+on+Tees%2C+Croft+on+Tees%2C+County+Durham%2C+United+Kingdom&ssne=&ssne_untouched=&label=gen173nr-1FCAEoggI46AdIM1gEaFCIAQGYAQm4ARfIAQzYAQHoAQH4AQuIAgGoAgO4AqX8va4GwAIB0gIkYzYwOTI3MzktMTFmYS00OWVmLTg5NTctNTE4OWE4ZTMwMjQ52AIG4AIB&sid=0ee5ca0b518c84f2a310109137773e8d&aid=304142&lang=en-gb&sb=1&src_elem=sb&src=index&dest_id=826aabd1-4c9138f73e8e066eb8a5627de361d018-en&dest_type=latlong&place_id=826aabd1-4c9138f73e8e066eb8a5627de361d018-en&latitude=54.4830148&longitude=-1.554551&ac_position=0&ac_click_type=b&ac_langcode=en&ac_suggestion_list_length=1&search_selected=true&search_pageview_id=ca546c5233e500fb&ac_meta=IAAoBDICZW4%3D&checkin=2024-12-01&checkout=2024-12-02&group_adults=2&no_rooms=1&group_children=0&sb_travel_purpose=leisure&flex_window=1"
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-600 dark:text-green-500 hover:underline text-lg md:text-xl"
                      style={{ color: "SlateBlue" }}
                    >
                      Booking.com
                    </a>{" "}
                    and search for hotels around DL2 2ST to see all of the
                    nearby hotel options.
                  </p>
                </>
                <>
                  <hr className="w-48 h-1 mx-auto my-1 bg-gray-100 border-3 my-3 rounded md:my-4 dark:bg-gray-700" />
                  <h2 className="flex items-center justify-center text-center p-3 text-lg md:text-xl font-bold pt-7">
                    Ozzy
                  </h2>
                  <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                    Our dog Ozzy will be joining us at various points during the
                    day.
                  </p>
                  <p className="text-center p-3 pb-0 text-base md:text-lg">
                    He is a very well behaved boy and will also be accompanied
                    by a chaperone all day.
                  </p>
                  <p className="text-center p-3 pb-0 text-base md:text-lg">
                    He won't be free to roam and approach you. However, you are
                    more than welcome to approach him and give him a fuss and
                    some attention, he is very friendly.
                  </p>
                </>

                {fullGuestStatus && (
                  <>
                    <hr className="w-48 h-1 mx-auto my-1 bg-gray-100 border-3 my-3 rounded md:my-4 dark:bg-gray-700" />{" "}
                    <h2
                      className="flex items-center justify-center text-center p-3 text-lg md:text-xl font-bold pt-7"
                      style={{ fontVariant: "normal" }}
                    >
                      {" "}
                      The Reception
                    </h2>
                    <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                      After the ceremony the reception will continue at the
                      Croft Hotel where we can spend some time together, take
                      photos and snack on canapes - your first drink is on us!
                    </p>
                    <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                      Following this we will be having an indoor wedding BBQ at
                      the venue at 4:00pm.
                    </p>
                    <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                      There will also be food available for all of our guests
                      later in the evening to keep you going throughout the day.
                    </p>
                    <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                      The party continues on until 12pm where there will be
                      music and dancing as well as space for relaxing.
                    </p>
                  </>
                )}
                <>
                  <hr className="w-48 h-1 mx-auto my-1 bg-gray-100 border-3 my-3 rounded md:my-4 dark:bg-gray-700" />
                  <>
                    <h2
                      ref={RsvpRef}
                      className="flex items-center justify-center text-center p-3 text-lg md:text-xl font-bold pt-7"
                    >
                      RSVP Details
                    </h2>
                    {guests.map((guest, index) => {
                      return (
                        <>
                          <Rsvp key={index} guest={guest} />
                          <hr className="w-48 h-1 mx-auto my-1 bg-gray-100 border-3 my-3 rounded md:my-4 dark:bg-gray-700" />{" "}
                        </>
                      );
                    })}
                  </>
                </>

                <>
                  <h2 className="flex items-center justify-center text-center p-3 text-lg md:text-xl font-bold pt-7">
                    Gifts
                  </h2>
                  <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                    Your presence at our wedding is the greatest gift we could
                    ask for, and we truly don't expect anything more. However,
                    if you do wish to contribute, a small donation towards our
                    honeymoon would be greatly appreciated.
                  </p>
                </>

                <>
                  <hr className="w-48 h-1 mx-auto my-1 bg-gray-100 border-3 my-3 rounded md:my-4 dark:bg-gray-700" />
                  <h2 className="flex items-center justify-center text-center p-3 text-lg md:text-xl font-bold pt-7">
                    Evening Song Recommendations
                  </h2>
                  <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                    We're still curating a playlist for the evening and would
                    love to have your recommendations for any songs that you'd
                    like to hear throughout the night.
                  </p>
                  <form
                    onSubmit={updateSongSelection}
                    className="p-3 justify-items-center"
                  >
                    <input
                      required
                      type="text"
                      placeholder="Artist"
                      value={artist}
                      onChange={(e) => setArtist(e.target.value)}
                      className="bg-gray-200 rounded pl-4 py-1 mb-2 focus:outline-none border mx-3"
                    />
                    <input
                      required
                      type="text"
                      placeholder="Song Name"
                      onChange={(e) => setTrack(e.target.value)}
                      value={track}
                      className="bg-gray-200 rounded pl-4 py-1 mb-2 focus:outline-none border mx-3"
                    />
                    <button
                      className="bg-tahiti hover:bg-green border border-black-500 hover:border-transparent py-1 mt-3 px-3 text-white rounded m-auto block"
                      style={{ fontVariant: "small-caps" }}
                      type="submit"
                    >
                      request song
                    </button>
                  </form>
                </>
                <hr className="w-48 h-1 mx-auto my-1 bg-gray-100 border-3 my-3 rounded md:my-4 dark:bg-gray-700" />
                <h2 className="flex items-center justify-center text-center p-3 text-lg md:text-xl font-bold pt-7">
                  Contact Us
                </h2>
                <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                  Please feel free to contact the bride and groom if there is
                  anything else you need to know.
                </p>
                <p className="flex items-center justify-center text-center p-3 text-base md:text-lg">
                  <a href="mailto: guppywedding24@gmail.com">
                    guppywedding24@gmail.com
                  </a>
                </p>
              </>
            </div>
          </div>
          <div className="max-w-none w-screen m-0 p-0 h-24 md:h-48 bg-[url('./assets/bottom.svg')] bg-cover bg-no-repeat"></div>
        </>
      )}
    </>
  );
};

export default Full;
