import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import Home from "./pages/Home";
import Full from "./pages/Full";
import Save from "./pages/Save";
import Invite from "./pages/Invite";
import { auth } from "./firebase/config";
import { onAuthStateChanged } from "firebase/auth";
import "./index.css";

function App() {
  const { authIsReady, user } = useAuthContext();
  const [isUser, setIsUser] = useState(user);

  onAuthStateChanged(auth, (user) => {
    setIsUser(user);
  });

  return (
    <div
      className="w-screen h-screen"
      style={{
        fontFamily: "Lora",
      }}
    >
      {authIsReady && (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={isUser ? <Full /> : <Home />} />
            <Route
              path="/invite"
              element={
                isUser && user.email === "guppywedding24@gmail.com" ? (
                  <Invite />
                ) : (
                  <Home />
                )
              }
            />
            {/*   <Route path="/save" element={<Save />} /> */}
            {/* <Route
              path="/full"
              element={isUser ? <Full /> : <Navigate to="/" />}
            /> */}
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;