import React from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { useState } from "react";
import { useLogin } from "../hooks/useLogin";
import { auth } from "../firebase/config";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const { authIsReady } = useAuthContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, isPending } = useLogin();

  const handleSubmit = (e) => {
    e.preventDefault();
    login(auth, email, password);
  };

  const routeChange = () => {
    let path = "/save";
    navigate(path);
  };

  const navigate = useNavigate();

  return (
    <div className="place-content-center content-center h-full">
      <div className="max-w-none w-screen m-0 p-0 h-0 md:h-48 md:bg-[url('./assets/top.svg')] bg-cover bg-no-repeat"></div>
      <div className="flex flex-col items-center justify-items-center my-3 min-h-[45rem] sm:min-h-[54rem] max-w-[40rem] sm:max-w-[48rem] bg-cover bg-center md:min-h-max bg-no-repeat m-auto bg-[url('./assets/border.svg')] md:bg-none">
        {authIsReady && (
          <div className="text-center m-auto w-auto content-center">
            <h1 className="text-4xl p-4">DAN & EMMA</h1>
            <p className="p-1 pb-4">PLEASE SIGN IN TO RSVP</p>
            <form className="p-0 justify-items-center" onSubmit={handleSubmit}>
              <div className="text-xl mb-4">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  type="email"
                  id="email"
                  className="bg-gray-200 rounded pl-4 py-2 focus:outline-none border mx-3 text-base"
                  placeholder="Your Email"
                />
              </div>
              <div className="text-xl mb-5">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value.toLowerCase())}
                  type="text"
                  id="password"
                  className="bg-gray-200 rounded pl-4 py-2 focus:outline-none border mx-3 text-base"
                  placeholder="Secret Code"
                />
              </div>
              {!isPending && (
                <button
                  className="bg-tahiti py-1 px-3 text-white uppercase rounded m-auto block "
                  style={{ fontVariant: "small-caps" }}
                >
                  sign in
                </button>
              )}
              {isPending && (
                <button
                  className="bg-tahiti py-1 px-3 text-white uppercase rounded m-auto block "
                  style={{ fontVariant: "small-caps" }}
                  disabled
                >
                  sign in
                </button>
              )}
              {error && (
                <>
                  <p className="text-center p-3 pb-0 px-7 text-base mb-5 md:text-lg">
                    Secret code or email not recognised
                  </p>
                  <p className="text-center pt-1 pb-0 px-7 text-base mb-5 md:text-lg">
                    Please make sure you are using the same email address we
                    used to send your RSVP to you and the secret code we sent
                    you
                  </p>
                </>
              )}
            </form>
          </div>
        )}
      </div>
      <div className="max-w-none w-screen m-0 p-0 h-0 md:h-48 md:bg-[url('./assets/bottom.svg')] bg-cover bg-no-repeat"></div>
      {/* <p className="text-center p-3 text-base mt-8 md:text-lg">
        Psst we've sent you the secret code by email
      </p>
      <p className="text-center p-3 text-base mb-5 md:text-lg">
        If you've not given us your email address yet...
      </p>
      <button
        className="bg-tahiti py-1 px-3 mb-8 text-white uppercase rounded m-auto block "
        style={{ fontVariant: "small-caps" }}
        onClick={routeChange}
      >
        Request secret code
      </button>
      <hr className="border-0 my-8" /> */}
    </div>
  );
};

export default Home;
