import React from "react";

const InterpretResponse = ({ dietaryRequirements, drink, rsvp, fullGuest }) => {
  return (
    <>
      <p className="flex items-center justify-center text-center p-2 text-base md:text-lg">
        Thanks for{" "}
        {rsvp && rsvp !== "Unconfirmed"
          ? "confirming your attendance"
          : "letting us know you can't make it"}
      </p>
      {rsvp && (
        <>
          {fullGuest && drink && (
            <>
              <p className="p-3 font-bold text-base md:text-lg">Drink choice</p>
              <i className="p-3 text-base md:text-lg">{drink}</i>
            </>
          )}
          {dietaryRequirements !== "None" && (
            <>
              <p className="p-3 font-bold text-base md:text-lg">
                Your dietary Requirements
              </p>
              <i className="p-3 text-base md:text-lg">{dietaryRequirements}</i>
            </>
          )}
        </>
      )}
    </>
  );
};

export default InterpretResponse;
