import React from "react";
import { useState } from "react";
import { db } from "../firebase/config";
import { useAuthContext } from "../hooks/useAuthContext";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import InterpretResponse from "./InterpretResponse";
import PlusOneResponse from "./PlusOneResponse";
import PropTypes from "prop-types";
import emailjs from "emailjs-com";

const Rsvp = ({ guest }) => {
  const user = useAuthContext();
  const email = user.user.email;
  const guestName = guest.firstName + guest.secondName;
  const separatedName = `${guest.firstName} ${guest.secondName}`;
  const fullGuest = guest.fullGuest;

  const [rsvp, setRsvp] = useState(guest.rsvp ? guest.rsvp : "Unconfirmed");
  const [guestRsvp, setGuestRsvp] = useState(
    guest.guest?.rsvp ? guest.guest?.rsvp : "Unconfirmed"
  );
  const [dietaryRequirements, setDietaryRequirements] = useState(
    guest.dietaryRequirements ? guest.dietaryRequirements : "None"
  );
  const [guestDietaryRequirements, setGuestDietaryRequirements] = useState(
    guest.guest?.dietaryRequirements ? guest.guest?.dietaryRequirements : "None"
  );
  const [drink, setDrink] = useState(guest.drink ? guest.drink : "Prosecco");
  const [guestDrink, setGuestDrink] = useState(
    guest.guest?.drink ? guest.guest?.drink : "Prosecco"
  );
  const [responded, setResponded] = useState(guest.respondedAlready);
  const plusOne = guest.plusOne;
  const [plusOneDetails, setPlusOneDetails] = useState("Declined");
  const guestType = guest.fullGuest ? "Full" : "Evening";
  const [guestFirstName, setGuestFirstName] = useState(guest.guest?.firstName);
  const [guestSecondName, setGuestSecondName] = useState(
    guest.guest?.secondName
  );
  const guestFullName = `${guestFirstName} ${guestSecondName}`;
  const guestDashboardRef = `${guestFirstName} ${guestSecondName} (${guestName}'s Guest)`;

  const docRef = doc(db, "rsvp", `${email}`);
  const dashboard = doc(db, "responses", `${guestType}`);
  const [occurrence, setOccurrence] = useState();

  const sendConfirmation = () => {
    // Not attending no plus one
    if (!rsvp && !plusOne) {
      return `${guestName} has responded.
    They confirmed that they can't make it.`;
    }
    // Not attending with plus one
    else if (!rsvp && plusOne) {
      return `${guestName} has responded.
        They confirmed that they can't make it, and they're not bringing their guest.`;
    }
    // Attending full guest, with no plus one
    else if (rsvp && fullGuest && !plusOne) {
      return `${guestName} has responded.
      They confirmed they will be attending.
    For their drink they chose ${drink}.
    Their dietary requirements are: ${dietaryRequirements}`;
    }
    // Attending evening guest, with no plus one
    else if (rsvp && !fullGuest && !plusOne) {
      return `${guestName} has responded.
      They confirmed that they will be attending. Their dietary requirements are: ${guestDietaryRequirements}`;
    }
    // Attending full guest - plus one confirmed
    else if (rsvp && fullGuest && plusOne && plusOneDetails === "Confirmed") {
      return `${guestName} has responded.
    They confirmed that they will be attending.
    For their drink they chose ${drink}.
    Their dietary requirements are: ${dietaryRequirements}.
    They are bringing a guest with them, their guest's name is: ${guestFullName}.
    For their drink they chose ${guestDrink}.
    Their dietary requirements are: ${guestDietaryRequirements}.`;
    }
    // Attending full guest - plus one declined
    else if (fullGuest && plusOne && plusOneDetails === "Declined") {
      return `${guestName} has responded.
    They confirmed that they will be attending.
    For their drink they chose ${drink}.
    Their dietary requirements are: ${dietaryRequirements}.
    They decided not to bring a guest.`;
    }
  };

  const emailMessage = {
    from_name: "Dan and Emma Wedding App",
    to_name: "The Happy Couple",
    message: sendConfirmation(),
    reply_to: "The Bride and Groom",
  };

  const errorMessage = {
    from_name: "Dan and Emma Wedding App",
    to_name: "The Happy Couple",
    message: `An error occurred in the app: the error was ${occurrence}, the user who experienced the error was: ${guestName}`,
    reply_to: "The Bride and Groom",
  };

  const bookPlusOne = () => {
    setGuestRsvp(true);
    if (rsvp && !fullGuest) {
      updateDoc(docRef, {
        [`${guestName}.guest`]: {
          [`rsvp`]: true,
          [`firstName`]: guestFirstName,
          [`secondName`]: guestSecondName,
          [`dietaryRequirements`]: guestDietaryRequirements,
        },
      }).catch((error) => {
        setOccurrence("failure when booking a plus one evening guest");
        sendEmail(errorMessage);
      });
    } else if (rsvp && fullGuest) {
      updateDoc(docRef, {
        [`${guestName}.guest`]: {
          [`rsvp`]: true,
          [`firstName`]: guestFirstName,
          [`secondName`]: guestSecondName,
          [`drink`]: guestDrink,
          [`dietaryRequirements`]: guestDietaryRequirements,
        },
      }).catch((error) => {
        setOccurrence("failure when booking a plus one full day guest");
        sendEmail(errorMessage);
      });
    }

    // update dashboard evening guest
    if (rsvp && !fullGuest) {
      updateDoc(dashboard, {
        [`Attending.${guestDashboardRef}.name`]: guestFullName,
        [`Attending.${guestDashboardRef}.dietaryRequirements`]:
          guestDietaryRequirements,
      }).catch((error) => {
        setOccurrence(
          "failure when adding a guest to responded document, evening guest"
        );
        sendEmail(errorMessage);
      });
    } else if (rsvp && fullGuest) {
      updateDoc(dashboard, {
        [`Attending.${guestDashboardRef}.name`]: guestFullName,
        [`Attending.${guestDashboardRef}.dietaryRequirements`]:
          guestDietaryRequirements,
        [`Attending.${guestDashboardRef}.drink`]: guestDrink,
      }).catch((error) => {
        setOccurrence(
          "failure when adding a guest to responded document, full day guest"
        );
        sendEmail(errorMessage);
      });
    }
  };

  const declinePlusOne = () => {
    setGuestRsvp(false);
    updateDoc(docRef, {
      [`${guestName}.guest`]: {
        [`rsvp`]: false,
      },
    }).catch((error) => {
      setOccurrence("failure when updating main doc for declined plus one");
      sendEmail(errorMessage);
    });

    // update dashboard
    updateDoc(dashboard, {
      NotAttending: arrayUnion(`${separatedName}'s Guest`),
    }).catch((error) => {
      setOccurrence("failure when adding a plus one to responded document");
      sendEmail(errorMessage);
    });
  };

  const updateRsvp = () => {
    sendEmail(emailMessage);
    setResponded(true);
    // Declined
    if (!rsvp) {
      updateDoc(docRef, {
        [`${guestName}.rsvp`]: rsvp,
        [`${guestName}.respondedAlready`]: true,
      }).catch((error) => {
        setOccurrence("failure when guest declining invite");
        sendEmail(errorMessage);
      });
    }
    // Accepted Evening Guest
    else if (rsvp && !fullGuest) {
      updateDoc(docRef, {
        [`${guestName}.rsvp`]: rsvp,
        [`${guestName}.respondedAlready`]: true,
        [`${guestName}.dietaryRequirements`]: dietaryRequirements,
      }).catch((error) => {
        setOccurrence("failure when guest accepting evening invite");
        sendEmail(errorMessage);
      });
    }
    // Accepted Full Day Guest
    else if (rsvp && fullGuest) {
      updateDoc(docRef, {
        [`${guestName}.rsvp`]: rsvp,
        [`${guestName}.respondedAlready`]: true,
        [`${guestName}.dietaryRequirements`]: dietaryRequirements,
        [`${guestName}.drink`]: drink,
      }).catch((error) => {
        setOccurrence("failure when guest accepting full day invite");
        sendEmail(errorMessage);
      });
    }

    // update dashboard
    // Not attending (no +1)
    if (!rsvp && rsvp !== "Unconfirmed" && !plusOne) {
      updateDoc(dashboard, {
        NotAttending: arrayUnion(`${separatedName}`),
      });
    }
    // Not attending (with +1)
    else if (!rsvp && rsvp !== "Unconfirmed" && plusOne) {
      updateDoc(dashboard, {
        NotAttending: arrayUnion(
          `${separatedName}`,
          `${separatedName}'s Guest`
        ),
      });
      // attending evening guest
    } else if (rsvp && rsvp !== "Unconfirmed" && !fullGuest) {
      updateDoc(dashboard, {
        [`Attending.${separatedName}.name`]: separatedName,
        [`Attending.${separatedName}.dietaryRequirements`]: dietaryRequirements,
      });

      // attending day guest
    } else if (rsvp && rsvp !== "Unconfirmed" && fullGuest) {
      updateDoc(dashboard, {
        [`Attending.${separatedName}.name`]: separatedName,
        [`Attending.${separatedName}.dietaryRequirements`]: dietaryRequirements,
        [`Attending.${separatedName}.drink`]: drink,
      }).catch((error) => {
        setOccurrence("failure when updating dashboard for fullGuest");
        sendEmail(errorMessage);
      });
    }
    if (plusOne && plusOneDetails === "Confirmed") {
      bookPlusOne();
    }
    if (plusOne && plusOneDetails === "Declined") {
      declinePlusOne();
    }
  };

  const sendEmail = (message) => {
    emailjs
      .send("service_ks5aro6", "template_3683fbb", message, "3_nlZ1YmfRa-FyB8d")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleDReq = (e) => {
    e.preventDefault();
    if (
      dietaryRequirements !== "None" &&
      dietaryRequirements !== "Unconfirmed" &&
      e.target.value === "Unconfirmed"
    ) {
      return;
    }
    setDietaryRequirements(e.target.value);
  };

  const handleGuestDReq = (e) => {
    e.preventDefault();
    if (
      guestDietaryRequirements !== "None" &&
      guestDietaryRequirements !== "Unconfirmed" &&
      e.target.value === "Unconfirmed"
    ) {
      return;
    }
    setGuestDietaryRequirements(e.target.value);
  };

  const handlePlusOne = (e) => {
    e.preventDefault();
    setPlusOneDetails(e.target.value);
  };

  return (
    <>
      <h3 className="flex items-center justify-center text-center p-3 text-xl md:text-2xl font-bold text-tahiti">
        {guest.firstName}
      </h3>
      {!responded && (
        <>
          <form onSubmit={updateRsvp}>
            <div className="flex flex-col items-center justify-items-center my-3 md:min-h-max m-auto">
              <h3 className="flex items-center justify-center text-center p-1 text-lg md:text-xl font-bold">
                Will you be attending?
              </h3>
              <>
                <label
                  htmlFor={`${guestName}-radio-1`}
                  className="pl-[1.5rem] flex items-center p-1 text-md md:text-lg"
                >
                  <input
                    required
                    id={`${guestName}-radio-1`}
                    type="radio"
                    name="rsvp"
                    className="relative float-left -ml-[1.5rem] mr-1 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-tahiti"
                    onChange={() => setRsvp(true)}
                  />
                  <p className="flex items-center justify-center text-center p-2 text-base md:text-lg">
                    I&apos;ll be there!
                  </p>
                </label>
                <label
                  htmlFor={`${guestName}-radio-2`}
                  className="pl-[1.5rem] flex items-center p-1 mb-3 text-md md:text-lg"
                >
                  <input
                    type="radio"
                    id={`${guestName}-radio-2`}
                    name="rsvp"
                    onChange={() => setRsvp(false)}
                    className="relative float-left -ml-[1.5rem] mr-1 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-tahiti"
                  />
                  <p className="flex items-center justify-center text-center px-2 text-base md:text-lg">
                    Sorry, I can&apos;t make it
                  </p>
                </label>
              </>
              {rsvp && rsvp !== "Unconfirmed" && fullGuest && (
                <>
                  <h3 className="flex items-center justify-center text-center p-3 mt-2 text-lg md:text-xl font-bold">
                    Please choose your post ceremony drink
                  </h3>
                  <>
                    <label
                      htmlFor={`${guestName}-radio-3`}
                      className="pl-[1.5rem] flex items-center p-1 text-md md:text-lg"
                    >
                      <input
                        className="relative float-left -ml-[1.5rem] mr-1 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-tahiti"
                        id={`${guestName}-radio-3`}
                        type="radio"
                        name="starter"
                        defaultChecked
                        onChange={() => setDrink("Prosecco")}
                      />
                      <p className="flex items-center justify-center text-center px-2 text-base md:text-lg">
                        Prosecco
                      </p>
                    </label>
                    <label
                      htmlFor={`${guestName}-radio-4`}
                      className="pl-[1.5rem] flex items-center p-1 text-md md:text-lg"
                    >
                      <input
                        className="relative float-left -ml-[1.5rem] mr-1 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-tahiti"
                        id={`${guestName}-radio-4`}
                        type="radio"
                        name="starter"
                        onChange={() => setDrink("Lager")}
                      />
                      <p className="flex items-center justify-center text-center px-2 text-base md:text-lg">
                        Lager
                      </p>
                    </label>
                    <label
                      htmlFor={`${guestName}-radio-5`}
                      className="pl-[1.5rem] flex items-center p-1 mb-0 text-md md:text-lg"
                    >
                      <input
                        className="relative float-left -ml-[1.5rem] mr-1 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-tahiti"
                        id={`${guestName}-radio-5`}
                        type="radio"
                        name="starter"
                        onChange={() => setDrink("Mocktail")}
                      />
                      <p className="flex items-center justify-center text-center px-2 text-base md:text-lg block">
                        Mocktail
                      </p>
                    </label>
                    <i className="flex items-center justify-center text-center px-2 text-xs md:text-sm block">
                      (Non-Alcoholic)
                    </i>
                  </>
                </>
              )}

              {rsvp && rsvp !== "Unconfirmed" && (
                <>
                  <h3 className="flex items-center justify-center text-center p-3 mt-2 text-lg md:text-xl font-bold">
                    Do you have any Dietary Requirements?
                  </h3>
                  <div className="max-w-screen-xl mx-auto p-4 flex flex-col items-center">
                    <div class="flex w-full relative">
                      <button
                        value={"Unconfirmed"}
                        onClick={handleDReq}
                        className={`bg-${
                          dietaryRequirements !== "None" ? "tahiti" : "white"
                        } py-1 px-3 ${
                          dietaryRequirements !== "None"
                            ? "text-white"
                            : "text-black"
                        } hover:bg-green hover:text-white rounded m-auto block`}
                      >
                        Yes
                      </button>
                      <button
                        value={"None"}
                        onClick={handleDReq}
                        className={`bg-${
                          dietaryRequirements === "None" ? "tahiti" : "white"
                        } py-1 px-3 ${
                          dietaryRequirements === "None"
                            ? "text-white"
                            : "text-black"
                        } hover:bg-green hover:text-white rounded m-auto block`}
                      >
                        No
                      </button>
                    </div>
                  </div>
                  {dietaryRequirements !== "None" && (
                    <textarea
                      required
                      rows="5"
                      cols="30"
                      className="border border-tahiti mt-3 p-4 text-md md:text-lg"
                      placeholder="Please tell us about any allergies or dietary requirements you would like us to be aware of"
                      onChange={(e) => setDietaryRequirements(e.target.value)}
                    />
                  )}
                </>
              )}

              {plusOne && rsvp && rsvp !== "Unconfirmed" && (
                <>
                  <h3 className="flex items-center justify-center text-center p-3 mt-2 text-lg md:text-xl font-bold">
                    You've been allocated a plus one <br />
                    Would you like to bring a guest?
                  </h3>
                  <>
                    <div className="max-w-screen-xl mx-auto p-4 flex flex-col items-center">
                      <div class="flex w-full relative">
                        <button
                          value={"Confirmed"}
                          onClick={handlePlusOne}
                          className={`bg-${
                            plusOneDetails !== "Declined" ? "tahiti" : "white"
                          } py-1 px-3 ${
                            plusOneDetails !== "Declined"
                              ? "text-white"
                              : "text-black"
                          } hover:bg-green hover:text-white rounded m-auto block`}
                        >
                          Yes
                        </button>
                        <button
                          value={"Declined"}
                          onClick={handlePlusOne}
                          className={`bg-${
                            plusOneDetails !== "Confirmed" ? "tahiti" : "white"
                          } py-1 px-3 ${
                            plusOneDetails !== "Confirmed"
                              ? "text-white"
                              : "text-black"
                          } hover:bg-green hover:text-white rounded m-auto block`}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </>
                </>
              )}

              {plusOneDetails === "Confirmed" && rsvp && (
                <>
                  <h3 className="flex items-center justify-center text-center p-3 mt-2 text-lg md:text-xl font-bold">
                    Please tell us about your Guest
                  </h3>
                  <>
                    <input
                      required
                      type="text"
                      placeholder="Guest's First Name"
                      onChange={(e) => setGuestFirstName(e.target.value)}
                      className="bg-gray-200 rounded pl-4 py-1 mb-2 focus:outline-none border mx-3"
                    />
                    <input
                      required
                      type="text"
                      placeholder="Guest's Second Name"
                      onChange={(e) => setGuestSecondName(e.target.value)}
                      className="bg-gray-200 rounded pl-4 py-1 mb-2 focus:outline-none border mx-3"
                    />
                  </>
                  {fullGuest && (
                    <>
                      <h3 className="flex items-center justify-center text-center p-3 mt-2 text-lg md:text-xl font-bold">
                        Please choose your guest's post ceremony drink
                      </h3>
                      <>
                        <>
                          <label
                            htmlFor={`${guestName}-radio-6`}
                            className="pl-[1.5rem] flex items-center p-1 text-md md:text-lg"
                          >
                            <input
                              className="relative float-left -ml-[1.5rem] mr-1 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-tahiti"
                              id={`${guestName}-radio-6`}
                              type="radio"
                              name="starter"
                              onChange={() => setGuestDrink("Prosecco")}
                              defaultChecked
                            />
                            <p className="flex items-center justify-center text-center px-2 text-base md:text-lg">
                              Prosecco
                            </p>
                          </label>
                          <label
                            htmlFor={`${guestName}-radio-7`}
                            className="pl-[1.5rem] flex items-center p-1 text-md md:text-lg"
                          >
                            <input
                              className="relative float-left -ml-[1.5rem] mr-1 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-tahiti"
                              id={`${guestName}-radio-7`}
                              type="radio"
                              name="starter"
                              onChange={() => setGuestDrink("Lager")}
                            />
                            <p className="flex items-center justify-center text-center px-2 text-base md:text-lg">
                              Lager
                            </p>
                          </label>
                          <label
                            htmlFor={`${guestName}-radio-8`}
                            className="pl-[1.5rem] flex items-center p-1 mb-0 text-md md:text-lg"
                          >
                            <input
                              className="relative float-left -ml-[1.5rem] mr-1 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-tahiti"
                              id={`${guestName}-radio-8`}
                              type="radio"
                              name="starter"
                              onChange={() => setGuestDrink("Mocktail")}
                            />
                            <p className="flex items-center justify-center text-center px-2 text-base md:text-lg block">
                              Mocktail
                            </p>
                          </label>
                          <i className="flex items-center justify-center text-center px-2 text-xs md:text-sm block">
                            (Non-Alcoholic)
                          </i>
                        </>
                      </>
                    </>
                  )}
                  <h3 className="flex items-center justify-center text-center p-3 mt-2 text-lg md:text-xl font-bold">
                    Does your guest have any Dietary Requirements?
                  </h3>
                  <div className="max-w-screen-xl mx-auto p-4 flex flex-col items-center">
                    <div class="flex w-full relative">
                      <button
                        value={"Unconfirmed"}
                        onClick={handleGuestDReq}
                        className={`bg-${
                          guestDietaryRequirements !== "None"
                            ? "tahiti"
                            : "white"
                        } py-1 px-3 ${
                          guestDietaryRequirements !== "None"
                            ? "text-white"
                            : "text-black"
                        } hover:bg-green hover:text-white rounded m-auto block`}
                      >
                        Yes
                      </button>
                      <button
                        value={"None"}
                        onClick={handleGuestDReq}
                        className={`bg-${
                          guestDietaryRequirements === "None"
                            ? "tahiti"
                            : "white"
                        } py-1 px-3 ${
                          guestDietaryRequirements === "None"
                            ? "text-white"
                            : "text-black"
                        } hover:bg-green hover:text-white rounded m-auto block`}
                      >
                        No
                      </button>
                    </div>
                  </div>
                  {guestDietaryRequirements !== "None" && (
                    <textarea
                      required
                      rows="5"
                      cols="30"
                      className="border border-tahiti mt-3 p-4 text-md md:text-lg"
                      placeholder="Please tell us if your guest has any allergies or dietary requirements that you would like us to be aware of"
                      onChange={(e) =>
                        setGuestDietaryRequirements(e.target.value)
                      }
                    />
                  )}
                </>
              )}
              <button
                className="bg-tahiti hover:bg-green border border-black-500 hover:border-transparent py-1 mt-3 px-3 text-white rounded m-auto block"
                style={{ fontVariant: "small-caps" }}
              >
                send rsvp
              </button>
            </div>
          </form>
        </>
      )}
      {responded && (
        <InterpretResponse
          dietaryRequirements={dietaryRequirements}
          drink={drink}
          rsvp={rsvp}
          fullGuest={fullGuest}
        />
      )}
      {responded && guestRsvp && guestRsvp !== "Unconfirmed" && (
        <PlusOneResponse
          dietaryRequirements={guestDietaryRequirements}
          drink={guestDrink}
          name={guestFirstName}
          fullGuest={fullGuest}
        />
      )}
    </>
  );
};

export default Rsvp;

Rsvp.propTypes = {
  guest: PropTypes.object,
};
