import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection } from "firebase/firestore"
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBWHFS169Jzlpzq70jo08iMZICVzHl74PA",
  authDomain: "dan-and-emma-wedding.firebaseapp.com",
  projectId: "dan-and-emma-wedding",
  storageBucket: "dan-and-emma-wedding.appspot.com",
  messagingSenderId: "890643378868",
  appId: "1:890643378868:web:c18113a20bb5da4c2bdde1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const colRef = collection(db, 'guests');

const auth = getAuth(app);
export { app, db, auth, colRef };

