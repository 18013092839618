import React from "react";

const PlusOneResponse = ({ drink, dietaryRequirements, name, fullGuest }) => {
  return (
    <>
      <p className="flex items-center justify-center text-center p-2 text-base md:text-lg">
        You've also let us know that you're bringing {name} as a guest
      </p>
      {fullGuest && drink && (
        <>
          <p className="p-3 font-bold text-base md:text-lg">
            {name}'s Drink choice
          </p>
          <i className="p-3 text-base md:text-lg">{drink}</i>
        </>
      )}
      {dietaryRequirements && dietaryRequirements !== "None" && (
        <>
          <p className="p-3 font-bold text-base md:text-lg">
            {name}'s Dietary Requirements
          </p>
          <i className="p-3 text-base md:text-lg">{dietaryRequirements}</i>
        </>
      )}
    </>
  );
};

export default PlusOneResponse;
